import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import LeftMenu from '../../components/leftMenu';
import AlertModal from '../../components/alertModal';
import { formatStrNumber, getFormattedDateTime, delComString, formatNumNumber } from '../../components/utils';
import { useSelector, useDispatch } from "react-redux";
import { Config } from "../../common/config";
import axios from "axios";
import { userLogin } from "../../reducers/userInfo";

export default function GiftManage(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const userInfo = useSelector((state) => state.userInfo);
    const receivedData = location.state;
    // console.log("receivedData : "+JSON.stringify(receivedData));
    const giftNo = receivedData.giftNo;
    // console.log("giftNo : ["+giftNo+"]");

    const [modalOpen, setModalOpen] = useState(false);   //모달 상태 관리 : 기본값 - 닫힘
    const [modalInfo, setModalInfo] = useState({isCancel:false, isConfirm:true, isMessage:true, isStopFirst: false, headerMsg:'', actionFlag:'', mainMsg:'', action:'', isPercent:false});
    const [giftData, setGiftData] = useState({});
    const [giftName, setgiftName] = useState('');
    const [categoryCode, setCategoryCode] = useState('GC01');
    const [categoryData, setCategoryData] = useState([]);
    const [imageFile, setImageFile] = useState(null);
    const [imageFileName, setImageFileName] = useState(null);
    const [giftDesc, setGiftDesc] = useState('');
    const [openAIGiftDescData, setOpenAIGiftDescData] = useState([]);
    const [openAISelectedData, setOpenAISelectedcData] = useState('');
    // const [price, setPrice] =  useState('');
    const today = getFormattedDateTime(new Date());
    // const [saleStartDttm, setSaleStartDttm] =  useState(today.slice(0, 10));
    const [saleEndDttm, setSaleEndDttm] =  useState('');
    const [saleStartDttmPre, setSaleStartDttmPre] =  useState('');
    const [saleEndDttmPre, setSaleEndDttmPre] =  useState('');
    const [validityMonth, setValidityMonth] =  useState('');
    const [validityDay, setValidityDay] =  useState('');
    const [validityMonthPre, setValidityMonthPre] =  useState('');
    const [validityDayPre, setValidityDayPre] =  useState('');
    const [countIssue, setCountIssue] =  useState('');
    const [countIssuePre, setCountIssuePre] =  useState('');
    const [transferTimeHour, setTransferTimeHour] =  useState('');
    const [transferTimeDay, setTransferTimeDay] =  useState('');
    const [transferTimeHourPre, setTransferTimeHourPre] =  useState('');
    const [transferTimeDayPre, setTransferTimeDayPre] =  useState('');
    const [isSalePeriodChecked, setIsSalePeriodChecked] = useState(false); 
    const [isValidityChecked, setIsValidityChecked] = useState(true); 
    const [isCountIssueChecked, setIsCountIssueChecked] = useState(false); 
    const [isTranferTimeChecked, setIsTranferTimeChecked] = useState(true); 
    const [regDttm, setRegDttm] = useState('');
    const [updDttm, setUpdDttm] = useState('');
    const [stopDesc, setStopDesc] = useState('');
    const [modDone, setModDone] = useState(false);

    const openModal = (e) => {
        // console.log("openModal actionFlag:"+modalInfo.actionFlag);
        if(modalInfo.actionFlag === 'stop'){
            // console.log("===== stop");
            setModalInfo({...modalInfo, actionFlag:'stop', isStopFirst: true, isCancel: true, mainMsg:'정말 중지 하시겠습니까?'});           
        }else if(modalInfo.actionFlag === 'mod'){
            // console.log("===== mod");
            if(giftData.state === 'SS03' || giftData.state === 'SS04'){
                setModalInfo({...modalInfo, actionFlag:'', isCancel: false, mainMsg:'이미 판매중지(종료)된 기프트라 수정할 수 없습니다.'});
            }else{
                setModalInfo({...modalInfo, actionFlag:'mod', isCancel: true, mainMsg:'정말 수정 하시겠습니까?'});
            }
        }else if(modalInfo.actionFlag === 'del'){
            // console.log("===== del");
            setModalInfo({...modalInfo, actionFlag:'del', isCancel: true, mainMsg:'정말 삭제 하시겠습니까?\n'+
                '기프트가 판매된 경우는 삭제가 불가능 합니다.'});
        }else if(modalInfo.actionFlag === 'api'){
            setModalInfo({...modalInfo, mainMsg:'API 서버에러입니다. 잠시 후 다시 시도해주세요.'});
        }

        setModalOpen(true);
     };
     
     const closeModal = () => {
    //    console.log("closeModal");
    //    if(modalInfo.actionFlag === 'stop'){
    //     modalInfo.isCancel = false ;
    //     }else if(modalInfo.actionFlag === 'del'){
    //         modalInfo.isCancel = false ;
    //     }
        setModalInfo({...modalInfo, actionFlag: '', isCancel: false, isConfirm: true});
        setModalOpen(false);
     };
 
     const doModal = () => {
    //    console.log("doModal actionFlag:"+modalInfo.actionFlag);
       setModalOpen(false);
       if(modalInfo.actionFlag === 'mod'){
           modalInfo.actionFlag ='' ;
           modGift();
       }else if(modalInfo.actionFlag === 'stop'){
            // modalInfo.actionFlag ='' ;
            stopGift();
       }else if(modalInfo.actionFlag === 'del'){
            modalInfo.actionFlag ='' ;
            delGift();
        }else if(modalInfo.actionFlag === 'goMain'){
            navigate('/go-main');
        }else if(modalInfo.actionFlag === 'openai'){
            modalInfo.actionFlag ='' ;
            // setGiftData({ ...giftData, giftDesc: openAISelectedData }); 
            setGiftDesc(openAISelectedData? openAISelectedData : giftData.giftDesc);
       } 
     
     };
  
    const categoryChange = (e) => {
        setGiftData({ ...giftData, categoryCode: e.target.value })
        // console.log('Selected Category:', e.target.value);
    };

    const giftDescChange = (e) => {
        setOpenAISelectedcData(e.target.value );
    };

    const salePeriodCheckboxChange = (e) => {
        setIsSalePeriodChecked(!isSalePeriodChecked); 

        if (e.target.checked) {
            setSaleEndDttm('');
        }else{
            setSaleEndDttm(saleEndDttmPre);
        }
    };

    const validyCheckboxChange = (e) => {
        const targetValue = e.target.value;
        const isChecked = e.target.checked;

        // console.log(`Selected value: ${targetValue}`);
        if (isChecked) {
            if (targetValue === 'true') {
                // console.log("true");
                setValidityDay('');
                setValidityMonth(validityMonthPre);
                setIsValidityChecked(true);
            } else {
                // console.log("false");
                setValidityMonth('');
                setValidityDay(validityDayPre);
                setIsValidityChecked(false);
            }
        }
    };

    const countIssueCheckboxChange = (e) => {
        setIsCountIssueChecked(!isCountIssueChecked); 

        if (e.target.checked) {
            // setGiftData({ ...giftData, countIssued: null });
            setCountIssue('');
        }else{
            // setGiftData({ ...giftData, countIssued: countIssuePre });
            setCountIssue(countIssuePre);
        }
    };

    const transferTimeCheckboxChange = (e) => {
        const targetValue = e.target.value;
        const isChecked = e.target.checked;

        // console.log(`Selected value: ${targetValue}`);
        if (isChecked) {
            if (targetValue === 'true') {
                // console.log("true");
                setTransferTimeDay('');
                setTransferTimeHour(transferTimeHourPre);
                setIsTranferTimeChecked(true);
            } else {
                // console.log("false");
                setTransferTimeHour('');
                setTransferTimeDay(transferTimeDayPre);
                setIsTranferTimeChecked(false);
            }
        }
    };
    const refetchJwt = async(refetchmod) => {
    
      try{
      const response = await axios.post(`${Config.API_SERVER}auth/getuser`, {
        userId: userInfo.id,
        pwd:userInfo.pwd
      })
  
      console.log("jwt재발급: ",response.data.data.accessToken)
      const jwtToken = response.data.data.accessToken;
      const user ={ 
        ...userInfo,
        jwtToken: jwtToken
      }
      console.log(user);
      dispatch(userLogin(user));
      if(refetchmod=="MOD_GIFT"){
        modGift(jwtToken)

      }else if(refetchmod=="DEL_GIFT"){
        delGift(jwtToken)

      }else{
      getData(jwtToken);
      }
    }catch(e){
      console.log(e);
    }
    }
    async function getData(newToken) {   
        try{
            const ret = await axios.get(
                `${Config.API_SERVER}code/detail`+
                "?pageSize=20&sortOrd=asc&groupCode=NG02",
                { headers: { 
                  Authorization: `Bearer ${newToken? newToken : userInfo.jwtToken}`,
                    },
                }
            );
            
            if(ret?.data?.data){
                // const count = ret.data.data.totalCount;
                // setBankData(JSON.stringify(ret.data.data.list));     
                setCategoryData(ret.data.data.list);                
            }

            const ret1 = await axios.get(
                Config.API_SERVER+"gift/"+giftNo,
                { headers: { 
                  Authorization: `Bearer ${newToken? newToken : userInfo.jwtToken}`,
                    },
                }
            );
            if(ret1?.data?.data){
                // console.log("data : "+JSON.stringify(ret.data.data ));           
                setGiftData(ret1.data.data);
                // console.log(ret.data.data.thumbnailFileUrl);
                if(ret1.data.data.thumbnailFileUrl !==  Config.API_SERVER){
                    setImageFile(ret1.data.data.thumbnailFileUrl);                    
                }
                const match1 = getMatch(ret1.data.data.validity);
                if(match1){
                    if(match1.unitPart === 'M'){
                        setValidityMonth(match1.numberPart);
                        setValidityMonthPre(match1.numberPart);
                    }else{
                        setValidityDay(match1.numberPart);
                        setValidityDayPre(match1.numberPart);
                    }
                }
                const match2 = getMatch(ret1.data.data.transferTime);
                if(match1){
                    if(match2.unitPart === 'H'){
                        setTransferTimeHour(match2.numberPart);
                        setTransferTimeHourPre(match2.numberPart);
                    }else{
                        setTransferTimeDay(match2.numberPart);
                        setTransferTimeDayPre(match2.numberPart);
                    }
                }
                setGiftDesc(ret1.data.data.giftDesc);
                setCountIssue(formatNumNumber(ret1.data.data.countIssued));
                setCountIssuePre(formatNumNumber(ret1.data.data.countIssued));
                setSaleStartDttmPre(ret1.data.data.saleStartDttm.slice(0,10));
                if(ret1.data.data.saleEndtDttm){
                    setSaleEndDttm(ret1.data.data.saleEndtDttm.slice(0,10));
                    setSaleEndDttmPre(ret1.data.data.saleEndDttm.slice(0,10));
                }
                setRegDttm(getFormattedDateTime(ret1.data.data.regDttm));
                setUpdDttm(getFormattedDateTime(ret1.data.data.updDttm));           
            }

        }catch (error) {
            console.error("Error getData:", error);
            if (error.response.data.resultCode === 401) {
              const autologin = localStorage.getItem("autologin");
              console.log(autologin);
              if(autologin){
                refetchJwt();                
              }else{
              modalInfo.actionFlag = 'login';
              modalInfo.mainMsg = "유효기간이 만료되었으니 다시 로그인해주세요";
              setModalOpen(true);
              }
            }
        }
    }

    const getMatch = (mixedData) => {
        const match = mixedData.match(/^(\d+)(\D+)/);
       
        if (match) {
            const numberPart = match[1]; 
            const unitPart = match[2]; 
            // console.log(numberPart, unitPart); 
            const sepData = {numberPart, unitPart};

            return sepData;
        }else{
            return null;
        }
    }

    const changeFile = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > 3 * 1024 * 1024) { // 3MB 이상인 경우
                e.target.value = null; // 파일 선택 취소
                modalInfo.mainMsg = '이미지 파일 크기는 3MByte를 초과할 수 없습니다.';
                setModalOpen(true);
            }else{
                setImageFile(URL.createObjectURL(file)); // 선택된 파일을 URL.createObjectURL을 사용하여 미리보기로 보여
                setImageFileName(file);
            }
        }else{
            setImageFile(null);
        }
    };    

    const changeDesc = async () => {

        try{
            const Data = {
                "messages": [
                    {
                    "role": "system",
                    "content": "당신은 카피라이터입니다. 당신의 임무는 짧은 기프트 광고 문구를 만드는 것입니다."
                    },
                    {
                    "role": "user",
                    "content": `"'${userInfo.storeName}'의 '${giftData.giftName}' 광고를 부탁드립니다. 이 광고 문구는 '${giftData.giftName}' 기프트를 구매하실 분들을 위한 소개가 될 것입니다. 소비자들은 이 문구를 읽고 제품이 매력적이라는 것을 느낄 것이고, 선물로 주고 싶다고 생각할 것입니다. '${userInfo.storeName}'은 광고 문구의 효과로 인해 눈에 띄는 판매 수익을 올릴 수 있을 것입니다. 광고를 위해 1~2줄을 적어주세요. 한글로 만들어주세요. 5가지 옵션으로 만들어주세요."`
                    }
                ],
                "temperature": 0.7,
                "top_p": 0.95,
                "frequency_penalty": 0,
                "presence_penalty": 0,
                "max_tokens": 800,
                "stop": null
            };
            // console.log(JSON.stringify(Data));

            modalInfo.mainMsg = '광고문구 생성중입니다.....';
            modalInfo.isCancel = false;
            modalInfo.isConfirm = false;
            openModal();

            // 광고 문구 생성 (OpenAI)
            const ret = await axios.post(`${Config.OPENAI_SERVER}`,
                Data,
                { headers: { 
                    "api-key": Config.OPENAI_KEY1,
                    },
                }
            );
            // console.log("Data : "+JSON.stringify(ret.data));
            // console.log(ret.data.choices[0].message.content);
            const getGiftDesc = ret.data.choices[0].message.content;

            const splittedArray = getGiftDesc.split('\n').map(line => {   
                let trimmedLine = line.trim(); 
                if (trimmedLine === "") return "";         
                trimmedLine = line.replace(/^\d+\./, ''); 
                trimmedLine = trimmedLine.replace(/^ "|"$/g, '');
                return trimmedLine.slice(0, 120); 
            });

            // console.log(splittedArray);    
            if(giftData.giftDesc){
                splittedArray.unshift(giftData.giftDesc);        
            }      
            setOpenAIGiftDescData(splittedArray);
            setModalInfo({...modalInfo, actionFlag: 'openai', isCancel: true, isConfirm: true});
            setModalOpen(true);

        } catch (error) {
            // 에러 발생 시의 코드
            setModalInfo({...modalInfo, isCancel: false, isConfirm: true});
            console.error("Error fetching data:", error);
        }
    }; 

    const checkModGift = () => {
        // console.log("=======================   checkModGift");

        if(!giftData.giftName)
        {
            modalInfo.mainMsg = '기프트명을 입력해 주세요.';
            return false;
        }
        if(!giftData.price)
        {
            modalInfo.mainMsg = '가격을 입력해 주세요.';
            return false;
        }
        if(!validityMonth && !validityDay)
        {
            modalInfo.mainMsg = '유효기간을 입력해 주세요.';
            return false;
        }else {
            if(isValidityChecked){
                if(!validityMonth){
                    modalInfo.mainMsg = '유효기간의 개월수를 입력해 주세요.';
                    return false;
                }
            }else{
                if(!validityDay){
                    modalInfo.mainMsg = '유효기간의 일수를 입력해 주세요.';
                    return false;
                }     
            }
        }
        if(!transferTimeHour && !transferTimeDay)
        {           
            modalInfo.mainMsg = '자동송금시간을 입력해 주세요.';
            return false;
        }else {
            if(isTranferTimeChecked){
                if(!transferTimeHour){
                    modalInfo.mainMsg = '자동송금시간의 시간을 입력해 주세요.';
                    return false;
                }
            }else{
                if(!transferTimeDay){
                    modalInfo.mainMsg = '자동송금시간의 일수를 입력해 주세요.';
                    return false;
                }     
            }
        }

        return true;      
    }

    // console.log("categoryData : "+JSON.stringify(categoryData));
    // console.log("giftData : "+JSON.stringify(giftData));

    const modGift = async (newToken) => {
     console.log("=======================   modGift");

        try{
            if (checkModGift() === true) {

                const formData = new FormData();
                formData.append('giftName', giftData.giftName);
                formData.append('giftId', giftData.giftId);           
                formData.append('categoryCode', giftData.categoryCode);
                if(giftData.giftDesc){
                    formData.append('giftDesc',  giftDesc);
                }
                // console.log(giftData.price);
                formData.append('price', parseInt(delComString(giftData.price)));
                formData.append('saleStartDttm', giftData.saleStartDttm.slice(0, 10));           
                if(saleEndDttm){                    
                    formData.append('saleEndDttm', saleEndDttm);   
                }
                if(isValidityChecked){
                    formData.append('validity', validityMonth+'M')
                }else{
                    formData.append('validity', validityDay+"D")
                }
                if(countIssue){
                    formData.append('countIssued', countIssue);
                }
                if(isTranferTimeChecked){
                    formData.append('transferTime', transferTimeHour+'H')
                }else{
                    formData.append('transferTime', transferTimeDay+'D')
                }
                if(stopDesc){
                    formData.append('state', 'SS03');  
                    formData.append('stopDesc', stopDesc);  
                }
                // 이미지 파일 추가
                // console.log("giftData.thumbnailFileUrl : "+giftData.thumbnailFileUrl);
                // console.log("imageFile.name : "+imageFile);
                // console.log("giftData : "+JSON.stringify(giftData));
                if(imageFile){
                    if(imageFile !== giftData.thumbnailFileUrl){
                        // console.log("file : "+imageFile);
                        formData.append("file", imageFileName);
                    }else{
                        // console.log("giftImageNo : "+giftData.giftImageNo);
                        formData.append('giftImageNo', giftData.giftImageNo);
                    }
                    
                }else{
                        // console.log("No....");
                }
                // console.log(" formData : "+JSON.stringify(formData));
                // for (const item of formData.entries()) {
                //     console.log(item[0] + ': ' + item[1]);
                // }

                // 기프트 정보 저장
                const ret = await axios.put(`${Config.API_SERVER}gift/${giftData.giftNo}`,
                    formData,
                    { headers: { 
                      Authorization: `Bearer ${newToken? newToken : userInfo.jwtToken}`,
                        },
                    }
                );
                // console.log("Data : "+JSON.stringify(ret.data));

                setModDone(true);
                setModalInfo({...modalInfo, isCancel: false, mainMsg: '요청정보를 성공적으로 수정하였습니다.'});
                setModalOpen(true);
            }else{
                setModalInfo({...modalInfo}); 
                setModalOpen(true);
            }  
        } catch (error) {
        // 에러 발생 시의 코드
            // console.error("Error fetching data : ", JSON.stringify(error));
            if (error.response) {
              if (error.response.data.resultCode === 401) {
                const autologin = localStorage.getItem("autologin");
                console.log(autologin);
                if(autologin){
                  refetchJwt("MOD_GIFT");                
                }else{
                modalInfo.actionFlag = 'login';
                modalInfo.mainMsg = "유효기간이 만료되었으니 다시 로그인해주세요";
                setModalOpen(true);
                }
              }
            } else if (error.request) {
                // 요청은 전송되었지만 응답이 없는 경우
                // alert("No response received from the server.");
                modalInfo.actionFlag = 'api';
                openModal();
            } else {
                // 요청을 설정하는 중에 에러가 발생한 경우
                // alert("Error setting up the request.");
                modalInfo.actionFlag = 'api';
                openModal();
            }
            console.error("Error fetching data:", error);
        }
    } 

    const stopGift = async() => {
        // console.log("stopGift");

        try{       
            if(!stopDesc){
                setModalInfo({...modalInfo, actionFlag:'stop', isStopFirst: false});
                setModalOpen(true);
            }else{
                modalInfo.actionFlag = '';
            }
        } catch (error) {
            // 에러 발생 시의 코드
            // console.error("Error fetching data : ", JSON.stringify(error));
            if (error.response) {
                if (error.response.data.resultCode === 401) {
                    modalInfo.actionFlag = 'goMain';
                    modalInfo.mainMsg = "유효기간이 만료되었으니 다시 로그인해주세요";
                    setModalOpen(true);
                  }else{
                    modalInfo.actionFlag = 'api';
                    openModal();
                  }
            } else if (error.request) {
                // 요청은 전송되었지만 응답이 없는 경우
                // alert("No response received from the server.");
                modalInfo.actionFlag = 'api';
                openModal();
            } else {
                // 요청을 설정하는 중에 에러가 발생한 경우
                // alert("Error setting up the request.");
                modalInfo.actionFlag = 'api';
                openModal();
            }
            console.error("Error fetching data:", error);
        }        
    };

    const delGift = async(newToken) => {
        // console.log("delStore");


        try{            
            const ret = await axios.delete(`${Config.API_SERVER}gift/${giftNo}`,
                                { headers: { 
                                  Authorization: `Bearer ${newToken? newToken : userInfo.jwtToken}`,
                                    },
                                }
                            );

            navigate('/gift-list');

        } catch (error) {
            // 에러 발생 시의 코드
            // console.error("Error fetching data : ", JSON.stringify(error));
            // console.error("Error fetching data : ", JSON.stringify(error.response.data));
            if (error.response) {
              if (error.response.data.resultCode === 401) {
                const autologin = localStorage.getItem("autologin");
                console.log(autologin);
                if(autologin){
                  refetchJwt("DEL_GIFT");                
                }else{
                modalInfo.actionFlag = 'login';
                modalInfo.mainMsg = "유효기간이 만료되었으니 다시 로그인해주세요";
                setModalOpen(true);
                }
              }
            }else if (error.request) {
                // 요청은 전송되었지만 응답이 없는 경우
                // alert("No response received from the server.");
                modalInfo.actionFlag = 'api';
                openModal();
            } else {
                // 요청을 설정하는 중에 에러가 발생한 경우
                // alert("Error setting up the request.");
                modalInfo.actionFlag = 'api';
                openModal();
            }
            console.error("Error fetching data:", error);
        }        
    };

    useEffect(() => {
        getData();

    }, [modDone]);

    // console.log("giftData : "+JSON.stringify(giftData));
    // console.log("regDttm : "+regDttm);
    // console.log("updDttm : "+updDttm);

    
    return (
    <>
        <AlertModal open={modalOpen} close={closeModal} action={doModal} header={modalInfo.headerMsg} 
            isConfirm={modalInfo.isConfirm} isMessage={modalInfo.isMessage} isCancel={modalInfo.isCancel}>
            {modalInfo.actionFlag === 'stop' && (giftData.state !== 'SS03' || giftData.state !== 'SS04') ? 
                <>
                    <p>{modalInfo.mainMsg}</p>
                    <br />
                    <p>중지사유 : </p>
                    <br />
                    <form>
                        <input className="input_04" type="text" name=""  placeholder="" maxLength={256}
                            onChange={e => {
                                setStopDesc(e.target.value);
                            }} 
                            value = {stopDesc}                                        
                        />
                    </form>
                    <br />
                    <p>중지일시 : </p>
                    <br />
                    <form>
                        <input className="input_04" type="text" name="" disabled style={{backgroundColor:  '#ddd', textAlign: 'center'}} value={getFormattedDateTime(new Date())}/>
                    </form>
                    <br />
                    {/* <p style={{color: 'red'}}>{modalInfo.isStopFirst? 'true' : 'false'} </p> */}
                    <p style={{color: 'red'}}>{(!stopDesc && !modalInfo.isStopFirst) && '입력오류 : 중지사유를 입력해 주세요'} </p>
                    <br />
                </>
            :
                <>  
                    {modalInfo.actionFlag === 'openai' ? 
                        <>
                            <p>광고문구를 선택해주세요.</p>
                            <br />
                            <form>
                                <select className="input_01" onChange={giftDescChange} value={openAISelectedData  || ''} >
                                        {openAIGiftDescData.map((item, index) => (
                                            <option key={index} value={item}>{item}</option>
                                    ))}
                                </select>
                            </form>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                        </>
                    :
                        <>
                            {modalInfo.mainMsg}

                        </>
                    }
                </>
            }
        </AlertModal>
    
        <section className="location_box">
                <ul className="location_001">
                    <li>홈</li>
                    <li>마이페이지</li>
                    <li>기프트 관리</li>
                    <li>기프트 수정/삭제</li>
                </ul>
        </section>

        <main>
            <section className="contants_box contants_box_mypage">

                <LeftMenu pageName='gift'/>

                <section className="rightcontant_box">
                    <h2 className="tit_01">기프트 수정/삭제</h2>

                    <article className="contants_box_03">
                        <table className="member_box">
                            <tbody>
                                <tr>
                                    <th>기프트 아이디</th>
                                    <td>
                                        <form>
                                            <input className="input_03" type="text" style={{backgroundColor:  '#ddd'}} name="" disabled value={giftData.giftId || ''} />
                                        </form>
                                    </td>
                                </tr>
                                <tr>
                                    <th>기프트 명</th>
                                    <td>
                                        <form>
                                            {giftData.state === 'SS03' || giftData.state === 'SS04' ?
                                                <input className="input_03" type="text" name=""  disabled style={{backgroundColor:  '#ddd'}} value={giftData.giftName || ''} />
                                            :
                                                <input className="input_03" type="text" name=""  maxLength={64} value={giftData.giftName || ''}
                                                    onChange={e => setGiftData({ ...giftData, giftName: e.target.value })}/>  
                                            }
                                        </form>
                                    </td>
                                </tr>
                                <tr>
                                    <th>카테고리</th>
                                    <td>
                                        <form>
                                            {giftData.state === 'SS03' || giftData.state === 'SS04' ?
                                                <input className="input_01" type="text" name=""  disabled style={{backgroundColor:  '#ddd'}} value={giftData.categoryCode || ''} />
                                            :
                                                <select className="input_01" onChange={categoryChange} value={giftData.categoryCode  || ''} >
                                                    {categoryData.map((category, index) => (
                                                        <option key={index} value={category.detailCode} selected={giftData.categoryCode === category.detailCode}>{category.detailCodeName}</option>
                                                ))}
                                            </select>
                                            }
                                        </form>
                                    </td>
                                </tr>
                                <tr>
                                    <th>기프트 사진</th>
                                    <td>
                                        <div className="signup_06">
                                            <div className="signup_06_002">
                                                {/* {imageFile && <img src={imageFile} alt="Selected" style={{ maxWidth: '100px', maxHeight: '100px' }} />} */}
                                                {imageFile && <img className="signup_img_xbtn" src={imageFile} alt="Selected"/>}
                                                {/* <a className="signup_img_xbtn" href="#"><img src="images/x_btn.png"/></a> */}
                                            </div>
                                            <div>
                                                {(giftData.state !== 'SS03' && giftData.state !== 'SS04') &&
                                                <>
                                                    <form>
                                                        <input type="file" name="fileInput" onChange={changeFile} accept=".jpg, .jpeg, .gif, .png" />
                                                    </form>
                                                    <p className="font_gray">※ jpg,jpeg,gif,png 파일 첨부 가능, 1개 파일을 3MByte까지 업로드 할 수 있습니다.</p>
                                                </>
                                                }
                                            </div>
                                        </div>
                                        {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div>
                                                <form>
                                                    <input type="file" name="fileInput" onChange={changeFile} accept=".jpg, .jpeg, .gif, .png" />
                                                </form>
                                                <p className="font_gray">※ jpg,jpeg,gif,png 파일 첨부 가능, 1개 파일을 3MByte까지 업로드 할 수 있습니다.</p>
                                            </div>
                                            <div style={{width: '100px'}}></div>
                                            <div>
                                                {imageFile && <img src={imageFile} alt="Selected" style={{ maxWidth: '100px', maxHeight: '100px' }} />}
                                            </div>
                                        </div> */}
                                    </td>                         
                                </tr>
                                <tr>
                                    <th>기프트 광고</th>
                                    <td>
                                        <form>
                                            {giftData.state === 'SS03' || giftData.state === 'SS04' ?
                                                <input className="input_04" type="text" name=""  disabled style={{backgroundColor:  '#ddd'}} value={giftData.giftDesc || ''} />
                                            :
                                            <>
                                                <input className="input_04" type="text" name=""  maxLength={128} value={giftDesc || ''}
                                                    onChange={e => setGiftDesc(e.target.value) } /> 
                                                <button type="button" className="button_05 button_gray" onClick={changeDesc} >광고문구 생성</button>  
                                            </>
                                            }
                                        </form>
                                    </td>
                                </tr>
                                <tr>
                                    <th>가격</th>
                                    <td>
                                        <form>
                                            {giftData.state === 'SS03' || giftData.state === 'SS04' ?
                                                <input className="input_01" type="text" name=""  disabled style={{backgroundColor:  '#ddd', textAlign: 'right'}} value={formatNumNumber(giftData.price) || ''} />
                                            :
                                                <input className="input_01" style={{textAlign: 'right'}} type="text" name="" maxLength={11} value={formatNumNumber(giftData.price) || ''}
                                                onChange={e => setGiftData({ ...giftData, price: formatNumNumber(e.target.value) })}/>
                                            }&ensp;원                                             
                                        </form>
                                    </td>
                                </tr>
                                <tr>
                                    <th>판매기간</th>
                                    <td>
                                        <form>
                                            {giftData.state === 'SS03' || giftData.state === 'SS04' ?
                                            <>
                                                <input className="input_05" type="text" name="" disabled style={{backgroundColor:  '#ddd'}} value={saleStartDttmPre? saleStartDttmPre : ''} />
                                                &ensp;&ensp;&ensp;~&ensp;&ensp;&ensp; 
                                                <input className="input_05" type="text" name="" disabled style={{backgroundColor:  '#ddd'}} value={saleEndDttmPre? saleEndDttmPre : ''} />
                                            </>
                                            :
                                            <>
                                                <input className="input_05" type="date" name=""
                                                    value={giftData.saleStartDttm && giftData.saleStartDttm.slice(0, 10)}
                                                    onChange={e => {
                                                        setGiftData({ ...giftData, saleStartDttm: (e.target.value) });
                                                        }}/>&ensp;&ensp;&ensp;~&ensp;&ensp;&ensp; 
                                                <input className="input_05" type="date" name=""
                                                    value={saleEndDttm || ''}
                                                    onChange={e => {                                                
                                                        setSaleEndDttm(e.target.value);
                                                        setSaleEndDttmPre(e.target.value);
                                                        }}/>
                                                <label className="check_box" style={{marginLeft: '20px'}}>&ensp;무기한
                                                    <input type="checkbox" checked={isSalePeriodChecked} onChange={salePeriodCheckboxChange} />
                                                    <span className="check_check" ></span>
                                                </label>
                                            </>
                                            }                                           
                                        </form>
                                    </td>
                                </tr>
                                <tr>
                                    <th>유효기간</th>
                                    <td>
                                        {giftData.state === 'SS03' || giftData.state === 'SS04' ?
                                        <>
                                            구매후&ensp;&ensp;&ensp;
                                            <label className="check_box">
                                                <input type="checkbox" name="" checked={validityMonthPre} />
                                                <span className="check_radio"></span>
                                            </label>
                                            <input className="input_02" type="text" name="" disabled style={{backgroundColor:  '#ddd', textAlign: 'right'}} 
                                                value={validityMonthPre || '' }/>&ensp;개월&ensp;&ensp;&ensp;
                                            <label className="check_box">
                                                <input type="checkbox" name="" checked={validityDayPre}   />
                                                <span className="check_radio"></span>
                                            </label>
                                            <input className="input_02" type="text" name="" disabled style={{backgroundColor:  '#ddd', textAlign: 'right'}}    
                                                value={validityDayPre || '' }/>&ensp;일
                                        </>
                                        :
                                        <>
                                            구매후&ensp;&ensp;&ensp;
                                            <label className="check_box">
                                                <input type="checkbox" name="validity" checked={isValidityChecked} value="true" onChange={validyCheckboxChange} />
                                                <span className="check_radio"></span>
                                            </label>
                                            <input className="input_02" type="text" name="" style={{textAlign: 'right'}}  maxLength={2}  
                                                value={validityMonth || '' }
                                                onChange={e => {                                                
                                                    setValidityMonth(formatStrNumber(e.target.value));
                                                    setValidityMonthPre(formatStrNumber(e.target.value));
                                                    }}/>&ensp;개월&ensp;&ensp;&ensp;

                                            <label className="check_box">
                                                <input type="checkbox" name="validity" checked={!isValidityChecked}  value="false" onChange={validyCheckboxChange} />
                                                <span className="check_radio"></span>
                                            </label>
                                            <input className="input_02" type="text" name="" style={{textAlign: 'right'}}  maxLength={3}   
                                                value={validityDay || '' }
                                                onChange={e => {                                                
                                                    setValidityDay(formatStrNumber(e.target.value));
                                                    setValidityDayPre(formatStrNumber(e.target.value));
                                                    }} />&ensp;일
                                        </>
                                        }    
                                    </td>
                                </tr>
                                <tr>
                                    <th>총발행수량</th>
                                    <td>
                                        <form>
                                            {giftData.state === 'SS03' || giftData.state === 'SS04' ?
                                            <>
                                                <input className="input_05" type="text" name=""  disabled style={{backgroundColor:  '#ddd', textAlign: 'right'}} value={countIssue !== '0' ? countIssue  : ''} />
                                                &ensp;개
                                            </>
                                            :
                                            <>
                                                <input className="input_05" type="text" name="" style={{textAlign: 'right'}}  maxLength={11}
                                                    value = {countIssue !== '0' ? countIssue  : ''}
                                                    onChange={e => {    
                                                            setCountIssue(formatStrNumber(e.target.value));                                          
                                                            setCountIssuePre(formatNumNumber(e.target.value));}} />&ensp;개&ensp;&ensp;&ensp;
                                                <label className="check_box">&ensp;무제한
                                                    <input type="checkbox" checked={isCountIssueChecked} onChange={countIssueCheckboxChange} />
                                                    <span className="check_check"></span>
                                                </label>
                                            </>
                                            }
                                        </form>
                                    </td>
                                </tr>
                                <tr>
                                    <th>누적판매수량</th>
                                    <td>
                                        <form>
                                            <input className="input_01" type="text" name="" style={{backgroundColor:  '#ddd', textAlign: 'right'}}  
                                                value = {formatNumNumber(giftData.countSold) || 0} disabled />&ensp;개
                                        </form> 
                                    </td>
                                </tr>
                                <tr>
                                    <th>누적판매금액</th>
                                    <td>
                                        <form>
                                            <input className="input_01" type="text" name="" style={{backgroundColor:  '#ddd', textAlign: 'right'}}  
                                                value = {giftData.countSold?  formatNumNumber(giftData.countSold * giftData.price) : 0} disabled />&ensp;원
                                        </form>
                                    </td>
                                </tr>
                                <tr>
                                    <th>상태</th>
                                    <td>
                                        <div className="m_salesstatus_box">
                                            <ul className="m_salesstatus_01">
                                                <li className={giftData.state === 'SS01'&& 'font_red' }>판매등록 ({regDttm.slice(0, 10)})</li>
                                                <li className={giftData.state === 'SS02'&& 'font_red'}>판매시작 ({saleStartDttmPre})</li>
                                                <li className={giftData.state === 'SS03' || giftData.state === 'SS04'? 'font_red' : 'font_gray'}>판매종료({updDttm.slice(0, 10)})</li>
                                            </ul>&nbsp;&nbsp;&nbsp;
                                            <button type="button" className="button_05 button_yellow" 
                                                onClick={e => {                   
                                                    setStopDesc('')                             ;
                                                    modalInfo.actionFlag = 'stop';
                                                    openModal();
                                                    }}>판매중지하기</button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>자동송금시간</th>
                                    <td>
                                        {giftData.state === 'SS03' || giftData.state === 'SS04' ?
                                        <>
                                            사용후&ensp;&ensp;&ensp;
                                            <label className="check_box">
                                                <input type="checkbox" name="" checked={transferTimeHourPre} />
                                                <span className="check_radio"></span>
                                            </label>
                                            <input className="input_02" type="text" name="" disabled style={{backgroundColor:  '#ddd', textAlign: 'right'}} 
                                                value={setTransferTimeHourPre || '' }/>&ensp;개월&ensp;&ensp;&ensp;
                                            <label className="check_box">
                                                <input type="checkbox" name="" checked={transferTimeDayPre}   />
                                                <span className="check_radio"></span>
                                            </label>
                                            <input className="input_02" type="text" name="" disabled style={{backgroundColor:  '#ddd', textAlign: 'right'}}    
                                                value={transferTimeDayPre || '' }/>&ensp;일
                                        </>
                                        :
                                        <>
                                            사용후&ensp;&ensp;&ensp;
                                            <label className="check_box">
                                                <input type="checkbox" name="transferTime" checked={isTranferTimeChecked} value='true' onChange={transferTimeCheckboxChange} />
                                                <span className="check_radio"></span>
                                            </label>
                                            <input className="input_02" type="text" name="" style={{textAlign: 'right'}}  maxLength={2} 
                                                value={transferTimeHour || '' }
                                                onChange={e => {                                                
                                                    setTransferTimeHour(formatStrNumber(e.target.value));
                                                    setTransferTimeHourPre(formatStrNumber(e.target.value));
                                                    }}/>&ensp;시간&ensp;&ensp;&ensp;

                                            <label className="check_box">
                                                <input type="checkbox" name="transferTime" checked={!isTranferTimeChecked} value='false' onChange={transferTimeCheckboxChange} />
                                                <span className="check_radio"></span>
                                            </label>
                                            <input className="input_02" type="text" name="" style={{textAlign: 'right'}}  maxLength={3}   
                                                value={transferTimeDay || '' }  
                                                onChange={e => {                                                
                                                    setTransferTimeDay(formatStrNumber(e.target.value));
                                                    setTransferTimeDayPre(formatStrNumber(e.target.value));
                                                    }}/>&ensp;일
                                        </>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th>등록일시</th>
                                    <td>
                                        <form>
                                            <input className="input_01" type="text" name="" disabled style={{backgroundColor:  '#ddd', textAlign: 'center'}} value={regDttm}/>
                                        </form>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </article>

                    <article className="button_box_01">                        
                        {((giftData.state !== 'SS04') && (giftData.delYn === 'N')) &&
                            <button type="button" className="button_01 button_03 button_gray_line font_red" value={""}
                                onClick = {(e) => {
                                    modalInfo.actionFlag = 'del';
                                    openModal(e);
                                }}>삭제</button>
                        }
                        <NavLink to='/gift-list'>
                            <button type="button" className="button_01 button_03 button_gray_line">취소</button>
                        </NavLink>
                        {(giftData.state !== 'SS03' && giftData.state !== 'SS04') &&
                            <button type="button" className="button_01 button_03 button_red"  value={""}
                                onClick = {(e) => {
                                    modalInfo.actionFlag = 'mod';
                                    openModal(e);
                                }}>수정</button>
                        }
                    </article>

                </section>

            </section>
        </main>
    </>
    );
}