import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import LeftMenu from "../../components/leftMenu";
import { useSelector, useDispatch } from "react-redux";
import AlertModal from "../../components/alertModal";
import { Config } from "../../common/config";
import axios from "axios";
import {
  formatStrNumber,
  formatNumNumber,
  getFormattedDateTime,
} from "../../components/utils";
import Pagination from "../../components/pagination";
import { userLogin } from "../../reducers/userInfo";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import translations from "../../data/translation";
import DatePicker, { registerLocale } from 'react-datepicker';


export default function giftRefund(props) {
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.userInfo);
  const language = useSelector((state) => state.language.language);
  const t = translations[language];
  console.log(userInfo);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    isCancel: false,
    isConfirm: true,
    isMessage: true,
    headerMsg: "",
    actionFlag: "",
    mainMsg: "",
    action: "",
    isPercent: false,
  });
  const today = getFormattedDateTime(new Date());
  const [searchCondition, setSearchCondition] = useState({
    flag: false,
    pageSize: Config.PAGE_SIZE_LIST[0],
    sortOrd: "",
    startDttm: "",
    endDttm: "",
    accountHolder: "",
    accountNo: "",
  });
  const [total, setTotal] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);

  const handleCancelPayment = (item) => {
    MySwal.fire({
      title: t.confirmRefund,
      html: (
        <div>
          <p>{t.refundAmountLabel}: {item.refundAmount}</p>
          <p>{t.cancelReason}: {item.cancelReason}</p>
        </div>
      ),
      icon: "info",
      showCancelButton: true,
      confirmButtonText: t.cancelPayment,
      cancelButtonText: t.close,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        cancelPaymentAPI(item);
      }
    });
  };

  const cancelPaymentAPI = async (item, newToken) => {
    console.log(newToken);
    try {
      const response = await axios.post(
        `${Config.API_SERVER}payment/toss/cancel`,
        {
          jwtToken: userInfo?.jwtToken,
          purchaseNo: item.purchaseNo,
          paymentKey: item.paymentKey,
          orderId: item.orderId,
          cancelReason: item.cancelReason,
          amount: item.refundAmount,
        },
        {
          headers: {
            Authorization: `Bearer ${newToken ? newToken : userInfo.jwtToken}`,
          },
        }
      );
      console.log(response);
      if (response) {
        Swal.fire(t.success, t.cancelPaymentSuccess, "success");
      }
    } catch (error) {
      console.log(error.response);
      Swal.fire(t.error, t.cancelPaymentError, "error");
      if (error.response?.data?.resultCode === 401) {
        const autologin = localStorage.getItem("autologin");

        if (autologin) {
          refetchJwt("paymentcancel", item);
        } else {
          Swal.fire(t.error, t.loginExpired, "error");
        }
      }
      console.error("Error getData:", error);
    }
  };

  const openModal = (e) => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const doModal = () => {
    setModalOpen(false);
    if (modalInfo.actionFlag === "goMain") {
      navigate("/go-main");
    }
  };

  const modGift = (giftNo) => {
    const passData = {
      giftNo: giftNo,
    };
    if (giftNo === 0) {
      navigate("/gift-content");
    } else {
      navigate("/gift-manage", { state: passData });
    }
  };

  const sales = (giftId) => {
    const passData = {
      giftId: giftId,
    };
    navigate("/gift-sales", { state: passData });
  };

  const refetchJwt = async (spec, item) => {
    console.log(userInfo);
    try {
      const response = await axios.post(`${Config.API_SERVER}auth/login`, {
        adminId: userInfo.id,
        password: userInfo.pwd,
      });

      console.log("jwt재발급: ", response.data.data.accessToken);
      const jwtToken = response.data.data.accessToken;
      const user = {
        ...userInfo,
        jwtToken: jwtToken,
      };
      console.log(user);
      dispatch(userLogin(user));
      if (spec === "paymentcancel") {
        cancelPaymentAPI(item, jwtToken);
      } else {
        getData(jwtToken);
      }
    } catch (e) {
      console.log(e);
    }
  };

  async function getData(newToken) {
    try {
      let params = "?storeNo=" + userInfo.storeNo;
      params += "&pageNo=" + currentPage;
      params += "&pageSize=" + searchCondition.pageSize;
      if (searchCondition.sortOrd.length > 0) {
        params += "&sortOrd=" + searchCondition.sortOrd;
      }
      if (searchCondition.startDttm.length > 0) {
        params += "&startDttm=" + searchCondition.startDttm;
      }
      if (searchCondition.endDttm.length > 0) {
        params += "&endDttm=" + searchCondition.endDttm;
      }
      if (searchCondition.accountNo.length > 0) {
        params += "&accountNo=" + searchCondition.accountNo;
      }
      if (searchCondition.accountHolder.length > 0) {
        params += "&accountHolder=" + searchCondition.accountHolder;
      }

      const ret = await axios.get(Config.API_SERVER + "refund" + params, {
        headers: {
          Authorization: `Bearer ${newToken ? newToken : userInfo.jwtToken}`,
        },
      });

      if (ret?.data?.data) {
        const count = ret.data.data.total;
        const tp = Math.ceil(count / searchCondition.pageSize);

        setTotalPage(tp);
        setTotal(count);
        setData(ret.data.data.list);
      }
      setSearchCondition({ ...searchCondition, flag: false });
    } catch (error) {
      if (error.response.data.resultCode === 401) {
        const autologin = localStorage.getItem("autologin");
        console.log(autologin);
        if (autologin) {
          refetchJwt();
        } else {
          modalInfo.actionFlag = "login";
          modalInfo.mainMsg = t.loginExpired;
          setModalOpen(true);
        }
      }
      console.error("Error getData:", error);
    }
  }

  const pageChange = (page) => {
    setCurrentPage(page);
  };

  const getSearch = () => {
    setSearchCondition({ ...searchCondition, flag: true });
  };

  const getClear = () => {
    setSearchCondition({
      ...searchCondition,
      sortOrd: "",
      startDttm: "",
      endDttm: "",
      giftIdOrd: "",
      accountHolder: "",
      accountNo: "",
    });
  };

  useEffect(() => {
    if (userInfo?.userNo !== "") {
      getData();
    }
  }, [currentPage, searchCondition.flag, userInfo]);

  return (
    <>
      <AlertModal
        open={modalOpen}
        close={closeModal}
        action={doModal}
        header={modalInfo.headerMsg}
        isConfirm={modalInfo.isConfirm}
        isMessage={modalInfo.isMessage}
        isCancel={modalInfo.isCancel}
      >
        {modalInfo.mainMsg}
      </AlertModal>

      <section className="location_box">
        <ul className="location_001">
          <li>{t.home}</li>
          <li>{t.myPage}</li>
          <li>{t.refundHistory}</li>
        </ul>
      </section>

      <main>
        <section className="contants_box contants_box_mypage">
          <LeftMenu pageName="gift" />

          <section className="rightcontant_box">
            <h2 className="tit_01">{t.refundHistory}</h2>

            <article className="table_search_box">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <form>
                        {t.searchPeriod}&ensp;
                       
                          <DatePicker
                                                        selected={searchCondition.startDttm ? new Date(searchCondition.startDttm) : null}
                                                        onChange={(date) =>
                                                          setSearchCondition({
                                                            ...searchCondition,
                                                            startDttm: date ? date.toISOString().split("T")[0] : "",
                                                          })
                                                        }
                                                        locale={language === "ko" ? "ko" : "en"}
                                                        dateFormat="yyyy-MM-dd"
                                                        className="search_input_01"
                                                        placeholderText={language === "ko" ? "시작 날짜" : "Start date"}
                                                      />
                        &nbsp;~&nbsp;
                     <DatePicker
                                               selected={searchCondition.endDttm ? new Date(searchCondition.endDttm) : null}
                                               onChange={(date) =>
                                                 setSearchCondition({
                                                   ...searchCondition,
                                                   endDttm: date ? date.toISOString().split("T")[0] : "",
                                                 })
                                               }
                                               locale={language === "ko" ? "ko" : "en"}
                                               dateFormat="yyyy-MM-dd"
                                               className="search_input_01"
                                               placeholderText={language === "ko" ? "종료 날짜" : "End date"}
                                             />
                      </form>
                    </td>
                    <td>
                      <form>
                        <select
                          className="search_input_02"
                          value={searchCondition.giftIdOrd}
                          onChange={(event) =>
                            setSearchCondition({
                              ...searchCondition,
                              giftIdOrd: event.target.value,
                            })
                          }
                        >
                          <option
                            value=""
                            selected={searchCondition.giftIdOrd === ""}
                          >
                            --{t.giftId}--
                          </option>
                          <option
                            value="desc"
                            selected={searchCondition.giftIdOrd === "desc"}
                          >
                            {t.descending}
                          </option>
                          <option
                            value="asc"
                            selected={searchCondition.giftIdOrd === "asc"}
                          >
                            {t.ascending}
                          </option>
                        </select>
                      </form>
                    </td>
                    
                    <td>
                      <form>
                        <input
                          className="search_input_03"
                          type="text"
                          name=""
                          placeholder={t.accountNo}
                          maxLength={20}
                          value={searchCondition.accountNo}
                          onChange={(event) =>
                            setSearchCondition({
                              ...searchCondition,
                              accountNo: event.target.value,
                            })
                          }
                        />
                      </form>
                    </td>
                    <td>
                      <form>
                        <input
                          className="search_input_03"
                          type="text"
                          name=""
                          placeholder={t.accountHolder}
                          maxLength={20}
                          value={searchCondition.accountHolder}
                          onChange={(event) =>
                            setSearchCondition({
                              ...searchCondition,
                              accountHolder: event.target.value,
                            })
                          }
                        />
                      </form>
                    </td>
                    <td>
                      <form>
                        <select
                          className="search_input_01"
                          value={searchCondition.sortOrd}
                          onChange={(event) =>
                            setSearchCondition({
                              ...searchCondition,
                              sortOrd: event.target.value,
                            })
                          }
                        >
                          <option
                            value=""
                            selected={searchCondition.sortOrd === ""}
                          >
                            --{t.registrationDate}--
                          </option>
                          <option
                            value="desc"
                            selected={searchCondition.sortOrd === "desc"}
                          >
                            {t.descending}
                          </option>
                          <option
                            value="asc"
                            selected={searchCondition.sortOrd === "asc"}
                          >
                            {t.ascending}
                          </option>
                        </select>
                      </form>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="button_05 button_black"
                        value={""}
                        onClick={getSearch}
                      >
                        {t.search}
                      </button>
                      <button
                        type="button"
                        className="button_05 button_gray_line"
                        value={""}
                        onClick={getClear}
                      >
                        {t.reset}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </article>

            <table className="table_box_01">
              <colgroup>
                <col width="60px" />
                <col width="220px" />
                <col width="100px" />
                <col width="120px" />
                <col width="100px" />
                <col width="140px" />
                <col width="100px" />
                <col width="100px" />
              </colgroup>

              <thead>
                <tr>
                  <th>No</th>
                  <th>{t.giftId}</th>
                  <th>{t.refundAmount}</th>
                  <th>{t.accountHolder}</th>
                  <th>{t.bank}</th>
                  <th>{t.accountNo}</th>
                  <th>{t.status}</th>
                  <th>{t.cancelPayment}</th>
                </tr>
              </thead>

              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.giftId}</td>
                    <td>{formatNumNumber(item.refundAmount)}{t.currency}</td>
                    <td>{item.accountHolder}</td>
                    <td>{item.bankCodeDesc}</td>
                    <td>{item.accountNo}</td>
                    <td>{t.stateDesc[item.stateDesc] || item.stateDesc}</td>
                    <td>
                      <button
                        type="button"
                        className="button_04 button_gray"
                        onClick={() => handleCancelPayment(item)}
                      >
                        {t.cancelPayment}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <p className="table_01">{t.total} : {total} {t.count}</p>

            <ul className="page_box">
              <Pagination
                totalPage={totalPage}
                onPageChange={pageChange}
              />
            </ul>
          </section>
        </section>
      </main>
    </>
  );
}
